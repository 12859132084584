<template>
    <v-card>
        <v-card tile flat :color="primaryColor" :class="textColor">
            <v-card-title>
                {{ $t("termsAndConditions") }}
            </v-card-title>
        </v-card>
        <v-card>
            <!-- <v-card-title>
                <v-spacer></v-spacer>
            </v-card-title> -->
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <p>
                                {{ $t("termsAndConditionsText") }}
                            </p>
                            <p>
                                <a href="https://www.salescloud.is/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and
                                    Conditions</a>
                            </p>
                            <p>
                            <v-checkbox class="mt-0 pt-0" :label="$t('confirmTermsAndConditionsAuthority')"
                                @click="confirmAuthority" v-model="TermsAndConditionsAuthority" />
                                <v-checkbox class="mt-0 pt-0" :label="$t('confirmTermsAndConditions')"
                                    @click="confirmTerms" v-model="TermsAndConditionsApproval" />
                            </p>
                            <p style="color: brown;">                                
                                {{ ErrorMessage }}
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeCallback">
                    {{ $t('close') }}
                </v-btn>
                <v-btn color="primary" :disabled="!Valid" @click="acceptTermsAndConditions">
                    {{ $t('confirm') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-card>
</template>

<script>
export default {
    name: "TermsAndConditions",
    props: {
        closeCallback: {
            type: Function,
            default: () => { console.warn("No closeCallback provided for TermsAndConditions") }
        },
    },
    data: () => ({
        TermsAndConditionsAuthority: false,
        TermsAndConditionsApproval: false,
        ErrorMessage: "",
        Valid: false
    }),
    computed: {
        primaryColor() {
            return this.$store.getters.primaryColor
        },
        textColor() {
            return this.$store.getters.textButtonColor(this.primaryColor)
        }
    },
    methods: {
        confirmAuthority() {
            this.Valid = this.TermsAndConditionsAuthority && this.TermsAndConditionsApproval
        },
        confirmTerms() {
            this.Valid = this.TermsAndConditionsApproval && this.TermsAndConditionsAuthority
        },
        async acceptTermsAndConditions() {
            const result = await this.$store.dispatch("acceptTermsAndConditions", {termsChecked: this.TermsAndConditionsApproval, authorityChecked: this.TermsAndConditionsAuthority})

            if(result.success) {
                this.closeCallback()
            } else {
                this.ErrorMessage = result.message
            }
        }
    }
}
</script>